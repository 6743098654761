import React from 'react';
// import VizSensor from 'react-visibility-sensor';

// import logo from './logo.svg'
import { makeStyles } from '@material-ui/core/styles';
import Header from './Header';
// import Header from './components/Header';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { CssBaseline } from '@material-ui/core';
// import tileData from './tileData';
// import Fade from '@material-ui/core/Fade';
import SectionText from './SectionText';
import ExplainerCard from './ExplainerCard';
import Footer from './Footer';
import {
    Link
  } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    appBar: {
        backgroundColor: "#fff" 
    },
    hero: {
        // backgroundColor: "rgba(120, 79, 165, 1)",
        background: `linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)`,
        // backgroundImage: `linear-gradient(116deg,#011934,#072a52)`,
        height: "570px",
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: "cover",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "4rem",
        color: "#fff",
        zIndex: 1000,
        [theme.breakpoints.down("sm")]: {
            height: "720px",
            fontSize: "2em"
        }
    },
    heroLogo: {
        height: "8rem",
        [theme.breakpoints.down("sm")]: {
            height: "4rem",
        }
    },
    heroImage: {
        boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
        width: "100%", 
        borderRadius: "10px",
    },
    coverFlex: {
        display: "flex",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",

        }
    },
    topButton: {
        display: "block",
        marginTop: "2rem",
        padding: theme.spacing(1.5)
    },
    sectionContainer: {
        paddingTop: theme.spacing(14),
        paddingBottom: theme.spacing(14),
        [theme.breakpoints.down("sm")]: {
            paddingTop: theme.spacing(5),
            paddingBottom: theme.spacing(5),
        },
    },
    bottomCover: {
        background: `linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: "cover",
        
    },
    bottomButton: {
        display: "block",
        margin: "2rem auto auto auto",
        padding: theme.spacing(1.5)
    },
    pageTitle: {
        
        textAlign: "center",
        fontWeight: "700",
        paddingBottom: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            fontSize: "2.6rem"
        }
    },
    alignCenter: {
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
}));


const Home = () => {

    const classes = useStyles();
    
    return (
        <div>
            <CssBaseline />
            <Header />

            <Grid className={classes.hero}>
                <Container maxWidth="lg" className={classes.sectionContainer}>
                    <Grid container spacing={4} className={classes.coverFlex}>
                        
                        <Grid item xs={12} md={6}>
                            
                            <Typography variant="h4" component="h4" >
                                Realize Your Dream of
                            </Typography>
                            <Typography variant="h2" component="h2" >
                                Home Ownership
                            </Typography>
                            <Typography variant="subtitle1" component="p" >
                                We know that each customer has specific needs, so we strive to meet those specific needs with a wide array of products, investment tools, mortgages and best of all quality service and individual attention.
                            </Typography>
                            <Link to="/q" >
                                <Button className={classes.topButton} variant="contained" color="primary" size="large">
                                    Get started now
                                </Button>
                            </Link>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <img src="https://images.unsplash.com/photo-1517087799209-ffb8d2962f40?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1489&q=80" alt="House" width="600px" 
                            className={classes.heroImage}
                            />
                        </Grid>
                    </Grid>
                    
                </Container>
            </Grid>
            <Container maxWidth="lg" className={classes.sectionContainer}>

                <SectionText title='Northeast Mortgage, Inc'
                    text="We know that each customer has specific needs, so we strive to meet those specific needs with a wide array of products, investment tools, mortgages and best of all quality service and individual attention.

                    Today's technology is providing a more productive environment to work in. For example, through our website you can submit a complete on-line, secure loan application or pre-qualify for a home loan. You may also evaluate your different financing options by using our interactive calculators and going over various mortgage scenarios." />
                <Grid container spacing={8} >

                    <ExplainerCard 
                        image="https://res.cloudinary.com/dhq0eewjv/image/upload/v1602431086/shutterstock_568476271-min_vl6fea.jpg"
                        title="Purchase"
                        desc="Whether you're buying your first home or your dream home, we have a mortgage solution for you. Get your custom rate quote today."
                    />
                    <ExplainerCard 
                        image="https://res.cloudinary.com/dhq0eewjv/image/upload/v1602431093/shutterstock_1377610472-min_rgoy38.jpg"
                        title="Refinance"
                        desc="We're committed to helping you refinance with the lowest rates and fees in the industry
                        today. Check out our Low Rates, Calculate your Payment, or Start the Process Today!"
                    />
                    <ExplainerCard 
                        image="https://res.cloudinary.com/dhq0eewjv/image/upload/v1602431091/shutterstock_1070261993-min_r7v8xw.jpg"
                        title="Apply"
                        desc="Whether you're buying your first home or your dream home, we have a mortgage solution for
                        you. Get your custom rate quote today."
                    />
                </Grid>
            </Container>

            <Grid className={classes.bottomCover}>
                {/* <Fade in={}> */}
                <Container maxWidth="lg" className={classes.sectionContainer}>
                    <Grid container className={classes.alignCenter}>
                        <Grid item xs={12} md={8}>
                            <Typography variant="h2" className={classes.pageTitle} style={{color: "#fff"}}>
                                Get started with your Digital Mortgage
                            </Typography>
                            <Typography variant="subtitle1" component="p" style={{color: "#fff", textAlign: 'center'}}>
                                No hassle, no obligation
                            </Typography>
                            <Link to="/q" >
                                <Button className={classes.bottomButton} variant="contained" color="primary" size="large">
                                    Get started now
                                </Button>
                            </Link>
                        </Grid>
                    </Grid>
                </Container>
                {/* </Fade> */}
            </Grid>
            <Footer />
        </div>
    );
}

export default Home;