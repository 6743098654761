import React from 'react';
import Portal from './components/Portal';
// import Route from './components/Route';
import Home from './components/Home';
import NotFound from './components/NotFound';
import Contact from './components/Contact';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    // Link
  } from "react-router-dom";
  


const App = () => {

    return (
        
        <Router>
            <div>
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route path="/q" component={Portal} />
                    <Route path="/contact" component={Contact} />
                    <Route component={NotFound} />
                </Switch>
            </div>
        </Router>
            // <Route path='/home'>
            //     <Home />
            // </Route>
            // <Route path='/'>
            //     <Portal />
            // </Route>
        
        


    );
}

export default App;