import * as React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import {Grid} from '@material-ui/core';
import {Link} from 'react-router-dom';

function Copyright() {
  return (
      <Grid>
        <Typography variant="body2" color="textSecondary" align="center">
          {'Copyright © '}
          <Link color="inherit" to="/">
            Northeast Mortgage, Inc
          </Link>{' '}
          {new Date().getFullYear()}
          {'.'}
        </Typography>
        <Typography variant="body2" color="textSecondary" align="center">
          (NMLS 1877014)
        </Typography>
      </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.background.paper,
    // marginTop: theme.spacing(8),
    padding: theme.spacing(6, 0),
  },
}));

export default function Footer() {
  const classes = useStyles();
  // const { description, title } = props;

  return (
    <footer className={classes.footer}>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item md={3} sm={3} xs={6}>
            <Typography variant="h6">
              About Us
            </Typography>
            <Typography>
              We've been helping customers afford the home of their dreams for many years and we love what we do.
            </Typography>
          </Grid>
          <Grid item md={3} sm={3} xs={6}>
            <Typography variant="h6">
              Office
            </Typography>
            <Typography>
              3860 Windermere Pkwy
              #205 Cumming, GA 30041
            </Typography>
          </Grid>
          <Grid item md={3} sm={3} xs={6}>
            <Typography variant="h6">
              Contact Us
            </Typography>
            <Link mailTo="Chinmaya@NEMortgage.Com">
              Chinmaya@NEMortgage.Com
            </Link>
            <Typography>
              # 470-506-4959 / 248-225-7919
            </Typography>
          </Grid>
          
        </Grid>
        
        {/* <Typography variant="h6" align="center" gutterBottom>
          {title}
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          color="textSecondary"
          component="p"
        >
          {description}
        </Typography> */}
      </Container>  
      <Container maxWidth="lg">
        <Copyright />
      </Container>
    </footer>
  );
}

Footer.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
};
