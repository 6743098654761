import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar } from '@material-ui/core';
import headerLogo from '../logo.png';


const useStyles = makeStyles((theme) => ({
  appBar: {
      backgroundColor: "#fff",
      boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
      zIndex: "tooltip",
  }
}));

const Header = () => {
  const classes = useStyles();

    return (
        <AppBar className={classes.appBar} position="static">
            <Toolbar>
                {<img src={headerLogo} alt="Northeast Mortgage, Inc" height="34rem" />}
            
            </Toolbar>
        </AppBar>
    );
}

export default Header;