import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

const useStyles = makeStyles((theme) => ({
    worksCard: {
        display: "flex",
        flexDirection: "column-reverse",
        justifyContent: "space-between",
        alignItems: "center",
        height: "100%",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "row",
            justifyContent: "space-between",
            
        },
        

    },
    worksMediaCard: {
        [theme.breakpoints.down("sm")]: {
            width: 150,
            height: "100%",
            overflow: "initial",
        }
        // width: 150,
    },
    workCardContent: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
}))


const ExplainerCard = ({image, title, desc}) => {
    const classes = useStyles();

    return (
        <Grid item md={4} sm={4} xs={12}>
            <Card className={classes.worksCard}>
                <CardContent className={classes.workCardContent}>
                    <Typography variant="h5" component="h6">
                        {title}
                    </Typography>
                    <Typography component="p" variant="subtitle2"  align="center" style={{fontWeight:400}}>
                        {desc}
                    </Typography>
                </CardContent>
                
                <CardMedia
                    component="img"
                    alt="Create your profile"
                    className={classes.worksMediaCard}

                    image={image}
                    title="Create your profile"
                />
            </Card>
        </Grid>
    );
}

export default ExplainerCard;