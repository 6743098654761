import React from 'react';
import {Link} from 'react-router-dom';
import SectionText from './SectionText';


const NotFound = () => {
    return (
        <div>
            <SectionText title="Oops! Page not found"
            text={<Link to="/">Go Back Home</Link>} 
            
            />
        </div>
    );
}


export default NotFound;