import React from 'react';
import Iframe from 'react-iframe';


class Portal extends React.Component {

    state = { loaded:false };

    componentWillMount() { 

        //render a script 
        const script = document.createElement("script");
        script.src = "https://cdn.floify.com/whitelabel.min.js";
        script.id = 'zen'
        //when the script loads, we're ready to go, so change state
        script.onload = (function(){ 
            this.setState({loaded: true});
            window.floifyWhiteLabel( '0604', 'https://nemortgage.floify.com/');
        }).bind(this);

        //append the script to the DOM
        //you should take care not to include it twice
        // and if include to setState to loaded just as script.onload does
        document.getElementsByTagName('body')[0].appendChild(script);
        
    }

    componentWillUnmount() { 

        //render a script 
        const script = document.getElementById("zen");
        
        document.getElementsByTagName('body')[0].removeChild(script);
    }

    render(){
        if(this.state.loaded === false){
            return (<div>Loading...</div>);
        }

        //call method from the script rendered
        return(
            <div>
                <Iframe id="0604" width="100%" margin="auto" height="1500px" frameborder="0"  />
                
                {this.componentWillMount}
                
                {/* {this.componentWillUnmount} */}
            </div>

            );
    }
}


export default Portal;