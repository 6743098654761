import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#764abc'
        },
        
    },
    background: {
        default: '#fff'
    }
})


export default theme;