import React from 'react';
// import Header from './components/Header';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    landing: {
        
        // margin: "0 10px",
        // height: "400px",
        margin: "0 auto",
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: "cover",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "4rem",
        [theme.breakpoints.down("sm")]: {
            fontSize: "2em"
        }
    },
    pageTitle: {
        
        textAlign: "center",
        fontWeight: "700",
        paddingBottom: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            fontSize: "2rem"
        }
    },
    titleDescription: {
        textAlign: "center",
        
        paddingBottom: theme.spacing(8),
        [theme.breakpoints.down("sm")]: {
            fontSize: "1.2rem",

        }
    },
}));


const SectionText = ({title, text}) => {
    const classes = useStyles();


    return (
        <Grid container direction="column" className={classes.landing}>
            <Grid xs={12} md={8}>
                <Typography variant="h2" component="h2" className={classes.pageTitle}>
                    {title}
                </Typography>
                <Typography variant="body1" component="p" className={classes.titleDescription} >
                    {text}
                </Typography>
            </Grid>
        </Grid>
    );
}

export default SectionText;