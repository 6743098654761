
import React from 'react';
import Header from './Header';
import SectionText from './SectionText';
import {makeStyles} from '@material-ui/core/styles';
import { 
    FormControl, 
    TextField, 
    Container, 
    Button, 
    InputLabel,
    Select,
    MenuItem, 
} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    inputBox: {
        marginBottom: "20px",
    },
}));

const Contact = () => {
    const classes = useStyles();

    return (
        <div>

            <Header />
            <Container MaxWidth="lg">
                
                <SectionText 
                title="Contact Us"
                text="You can email us directly to Chinmaya@NEMortgage.com, or simply drop your contact details and we'll get in touch with you."
                />
                
                <FormControl>
                    <TextField id="outlined-search" label="Full Name" type="search" variant="outlined" className={classes.inputBox} />
                    <TextField id="outlined-search" label="Email" type="search" variant="outlined" className={classes.inputBox} />
                    <TextField id="outlined-search" label="Phone" type="search" variant="outlined" className={classes.inputBox} />
                    
                    <FormControl variant="outlined" className={classes.inputBox}>

                        <InputLabel id="demo-simple-select-outlined-label">Age</InputLabel>
                        <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value=""
                        // onChange={handleChange}
                        label="Age"
                        >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                        </Select>
                    </FormControl>
                    <Button variant="contained" color="primary">Submit</Button>
                </FormControl>
                
                
            </Container>
        </div>
    );
}

export default Contact;